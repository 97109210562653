import Header from '../header/Header';
import './ht6.css';

const HT6 = () => {
    return (
        <div className='ht6' id='ht6'>
            <div className="ht6-wrapper">
                <Header/>
                <div className="ht6-content-wrapper">
                    <div className="ht6-title">hack the 6ix</div>
                    <div className="ht6-text">(stay tuned, coming soon!)</div>
                </div>
            </div>
        </div>
    )
}

export default HT6;