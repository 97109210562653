import './nopage.css';

const NoPage = () => {
    return (
        <div className='np'>
            <div className="np-wrapper">
                <div className="np-content">no page :( - go back to <a href="/" className="np-link">home</a>?</div>
            </div>
        </div>
    )
}

export default NoPage;